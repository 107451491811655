import _ from 'lodash'
import LogLevel from 'loglevel'

function handleRemoveWordHighLightElement(element, highlightClassname) {
  if (_.isEmpty(element) || !_.isEqual(element.nodeType, Node.ELEMENT_NODE)) {
    return
  }

  Array.prototype.slice.call(element.querySelectorAll(`span.${highlightClassname}`)).forEach(highlightElement => {
    highlightElement.outerHTML = highlightElement.innerHTML
  })
}

function handleAddWordHighLightElement(node, regex, highlightClassname) {
  if (!node) {
    return
  }

  if (!_.isEqual(node.nodeType, Node.TEXT_NODE)) {
    Array.prototype.slice.call(node.childNodes).forEach(childNode => {
      handleAddWordHighLightElement(childNode, regex, highlightClassname )
    })
    return
  }

  const text = node.nodeValue
  if (!text.match(regex)) {
    return
  }

  const { parentNode } = node
  const newInnerHTML = text.replace(regex, (match, s1) => `<span class="${highlightClassname}">${s1}</span>`)

  if (_.isEqual(parentNode.childNodes.length, 1)) {
    parentNode.innerHTML = newInnerHTML
    return
  }

  const spanElement = document.createElement('span')
  spanElement.innerHTML = newInnerHTML
  parentNode.replaceChild(spanElement, node)
}

export function highlightWords(element, words, highlightedClassname = 'word-highlighted') {
  if (_.isEmpty(element)) {
    return
  }

  handleRemoveWordHighLightElement(element, highlightedClassname)

  if (_.isEmpty(words)) {
    return
  }

  console.log(words, `(${words.join('|')})`)
  try {
    const regex = new RegExp(`(${words.join('|')})`, 'gmi')
    handleAddWordHighLightElement(element, regex, highlightedClassname)
  } catch (e) {
    LogLevel.getLogger('Highlight Words').error('Error Regex')
  }
}
