export const TOAST_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export const TOAST_ICONS = {
  [TOAST_TYPES.SUCCESS]: 'check circle',
  [TOAST_TYPES.WARNING]: 'warning sign',
  [TOAST_TYPES.ERROR]: 'warning circle',
}
